import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Productcards from './ProductCards';
import Productcategories from './ProductCategories';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

/* var size = Object.keys(exhibitors).length;
var products = [];
var videos = [];
var exprod = [];
    
for (let step = 0; step < size; step++) {
    for (let steps = 0; steps < Object.keys(exhibitors[step].products).length; steps++) {
        var theone = exhibitors[step].products[steps];
        theone.company = exhibitors[step].name;
        theone.hall = exhibitors[step].hall;
        products.push(theone);
    }
    for (let vsteps = 0; vsteps < Object.keys(exhibitors[step].videos).length; vsteps++) {
        var vtheone = exhibitors[step].videos[vsteps];
        vtheone.company = exhibitors[step].name;
        vtheone.hall = exhibitors[step].hall;
        videos.push(vtheone);
    }
}

products.map((tile) => (
    exprod.push(tile.name)
))

var uniqEs6 = (arrArg) => arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
const uniqueSet = uniqEs6(exprod)
 */

const sort = [{ label: 'A-Z', value: 'A-Z' }, { label: 'Z-A', value: 'Z-A' }]

class ProdFiltersearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            proddata: [],
            isloaded: false,
            error: false,
            search: '',
            errordata: '',
            categories: [],
            //defaultcategory: {label:'All',value:''},
            halls: [],
            defaulthall: { label: 'Overall', value: '' },
            defaultsort: { label: 'A-Z', value: 'A-Z' },
            selectedcategory: '',
            selectedcatestatus: false,
            nextpage: null,
        };
    };


    searchHandle = (e) => {
        e.persist();
        this.setState({ isloaded: false, error: false });
        if (e.target.value === '') {
            this.setState({ isloaded: true, selectedcatestatus: false });
        }
        else {
            axios.get(`${process.env.REACT_APP_API_HOST}/api/products?category_id=` + this.state.selectedcategory + `&hall_id=` + this.state.defaulthall.value + `&sort_by=` + this.state.defaultsort.value + `&keyword=` + e.target.value)
                .then(res => {
                    const data = res.data.data;
                    const nextpage = res.data.next_page_url;
                    this.setState({ proddata: data, isloaded: true, nextpage: nextpage, search: e.target.value, selectedcatestatus: true })
                })
                .catch(error => {
                    this.setState({ error: true, isloaded: true, errordata: error.data });
                })
        }
    }

    hallHandle = (options) => {
        this.setState({ isloaded: false, error: false });
        axios.get(`${process.env.REACT_APP_API_HOST}/api/products?hall_id=` + options.value + `&category_id=` + this.state.selectedcategory + `&sort_by=` + this.state.defaultsort.value + `&keyword=` + this.state.search)
            .then(res => {
                const data = res.data.data;
                const nextpage = res.data.next_page_url;
                this.setState({ proddata: data, isloaded: true, nextpage: nextpage, defaulthall: options });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error });
            })
    }

    categoryHandle = (options) => {
        this.setState({ isloaded: false, error: false });
        axios.get(`${process.env.REACT_APP_API_HOST}/api/products?category_id=` + options.value + `&hall_id=` + this.state.defaulthall.value + `&sort_by=` + this.state.defaultsort.value + `&keyword=` + this.state.search)
            .then(res => {
                const data = res.data.data;
                const nextpage = res.data.next_page_url;
                this.setState({ proddata: data, isloaded: true, nextpage: nextpage, defaultcategory: options });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error });
            })
    }

    sortHandle = (options) => {
        this.setState({ isloaded: false, error: false });
        axios.get(`${process.env.REACT_APP_API_HOST}/api/products?sort_by=` + options.value + `&category_id=` + this.state.selectedcategory + `&hall_id=` + this.state.defaulthall.value + `&keyword=` + this.state.search)
            .then(res => {
                const data = res.data.data;
                const nextpage = res.data.next_page_url;
                this.setState({ proddata: data, isloaded: true, nextpage: nextpage, defaultsort: options });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error });
            })
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_HOST}/api/categories`)
            .then(res => {
                const response = res.data;
                const reformattedData = response.map((tile) => {
                    return {
                        label: tile.name,
                        value: '' + tile.id,
                        image: tile.image,
                    };
                })
                reformattedData.sort(function (a, b) {
                    if (a.label < b.label) { return -1; }
                    if (a.label > b.label) { return 1; }
                    return 0;
                })
                this.setState({ categories: reformattedData });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error });
            })
        axios.get(`${process.env.REACT_APP_API_HOST}/api/halls`)
            .then(res => {
                const response = res.data;
                const reformattedData = response.map((tile) => {
                    return {
                        label: tile.name,
                        value: '' + tile.id,
                    };
                })
                /* reformattedData = reformattedData.filter(function( obj ) {
                    return obj.label.charAt() === '3';
                });
                const trueData = reformattedData.map(tile => {
                    return {
                        label: tile.label.substring(3),
                        value: tile.value
                    }
                }) */

                // sort reformattedData by label
                reformattedData.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({ halls: reformattedData, isloaded: true });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error });
            })
        //axios.get(`${process.env.REACT_APP_API_HOST}/api/products?page=1`)
        //    .then(res => {
        //        const data = res.data.data;
        //        this.setState({ proddata: data, isloaded: true });
        //    })
        //    .catch(error => {
        //        this.setState({ error: true, isloaded: true, errordata: error.data });
        //    })
        window.addEventListener('scroll', this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }

    selectCate = (e) => {
        this.setState({ isloaded: false });
        var catename = e.target.title
        axios.get(`${process.env.REACT_APP_API_HOST}/api/products?category_id=` + catename + `&sort_by=` + this.state.defaultsort.value)
            .then(res => {
                const data = res.data.data;
                const nextpage = res.data.next_page_url;
                this.setState({
                    proddata: data,
                    selectedcategory: catename,
                    selectedcatestatus: true,
                    isloaded: true,
                    nextpage: nextpage,
                    search: '',
                });
            })
            .catch(error => {
                this.setState({ error: true, isloaded: true, errordata: error, selectedcatestatus: true });
            })
    }

    sortCategory = (options) => {
        var list = this.state.categories;
        if (options.value === 'Z-A') {
            list.sort(function (a, b) {
                if (a.label < b.label) { return 1; }
                if (a.label > b.label) { return -1; }
                return 0;
            })
            this.setState({ categories: list })
        } else {
            list.sort(function (a, b) {
                if (a.label < b.label) { return -1; }
                if (a.label > b.label) { return 1; }
                return 0;
            })
            this.setState({ categories: list })
        }
    }

    onScroll = (e) => {
        e.preventDefault()
        if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 1) && this.state.nextpage && !this.state.appendLoading) {
            this.setState({ error: false, appendLoading: true });
            axios.get(this.state.nextpage + `&category_id=` + this.state.selectedcategory + `&hall_id=` + this.state.defaulthall.value + `&sort_by=` + this.state.defaultsort.value + `&keyword=` + this.state.search)
                .then(res => {
                    const data = res.data.data;
                    const nextpage = res.data.next_page_url;
                    var joined = this.state.proddata.concat(data);
                    this.setState({ proddata: joined, nextpage: nextpage, appendLoading: false })
                })
        }
    }

    render() {
        return (
            <div>
                <div className="py-3 border-bottom damn-sticky">
                    <div className="row filter-bar">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-4 align-self-center">
                                    {!this.state.selectedcatestatus && <div className="filter-main-title">Categories</div>}
                                    {this.state.selectedcatestatus && <div className="filter-main-title">Products</div>}
                                </div>
                                {<div className="col-8 p-0 input-m"><input type="text" onChange={this.searchHandle} placeholder="Search products here" /></div>}
                            </div>
                            <div className="d-flex align-self-end filter-m" style={{ height: 63, gap: 8 }}>
                                {!this.state.error && this.state.selectedcatestatus &&
                                    <div className="drop-sm flex-fill">
                                        <span className="drop-title">Select Hall</span>
                                        <Dropdown options={this.state.halls} onChange={this.hallHandle} value={this.state.defaulthall} placeholder="Select" />
                                    </div>
                                }
                                {!this.state.error && this.state.selectedcatestatus && <div className="drop-sm flex-fill">
                                    <span className="drop-title">Sort By</span>
                                    <Dropdown options={sort} onChange={this.sortHandle} value={this.state.defaultsort} placeholder="Select" />
                                </div>}
                                {!this.state.error && !this.state.selectedcatestatus && <div className="drop-sm flex-fill">
                                    <span className="drop-title">Sort By</span>
                                    <Dropdown options={sort} onChange={this.sortCategory} value={this.state.defaultsort} placeholder="Select" />
                                </div>}
                                {this.state.selectedcatestatus &&
                                    <div className="drop-sm flex-fill align-self-end">
                                        <button className="goback-btn" onClick={() => this.setState({ selectedcategory: '', selectedcatestatus: false, nextpage: null, error: false, defaulthall: { label: 'Overall', value: '' }, defaultsort: { label: 'A-Z', value: 'A-Z' } })}>Back to List</button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-sm-6 mt-auto input-d">
                            <input type="text" onChange={this.searchHandle} placeholder="Search products here" />
                        </div>
                    </div>
                </div>

                {!!this.state.isloaded ? (
                    !this.state.error ? (
                        <div id="listbox" style={{ paddingBottom: 80 }}>
                            {!this.state.selectedcatestatus ? (
                                <Productcategories categories={this.state.categories} handleclick={this.selectCate} />
                            ) : (
                                <Productcards products={this.state.proddata} />
                            )}
                        </div>
                    ) : (
                        <div id="listbox" style={{ margin: "auto", paddingTop: 50 }}>
                            No results found
                        </div>
                    )
                ) : (
                    <div className="mt-5">
                        <CircularProgress />
                    </div>
                )}
            </div>
        )
    };
};

export default ProdFiltersearch;