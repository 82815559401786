import React from 'react';
import axios from 'axios';
import SlidingPane from 'react-sliding-pane';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CloseIcon from '@material-ui/icons/Close';
import Modals from 'react-modal';
import Placeholder from '../images/placeholder.png';
import Eggthemighty from './Egglogo';
import wow360 from '../images/g18.png';
import { Productcategory, Productlist, Awardslist } from './ProductList';


class Slidingpane extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPaneOpenBottom: false,
      companyName: 'undefined',
      companyVR: 0,
      companyLogo: '',
      companyHall: '',
      companyDesc: '',
      companyProducts: '',
      companyVideos: '',
      companySite: '',
      companyWhatsapp: '',
      companyEmail: '',
      companyAwards: '',
      productCategories: '',
      companyShort: '',
      wechatBold: '',
    };
  };

  findArrayElementByTitle(array, name) {
    return array.find((element) => {
      return element.name === name;
    })
  }

  handleClick = (event) => {
    event.preventDefault()
    var targetCompany = this.props.exhibitor
    axios.get(`${process.env.REACT_APP_API_HOST}/api/exhibitors/` + targetCompany)
      .then(res => {
        const response = res.data;
        var zproducts = response.products;
        zproducts.forEach(function (item, i) {
          if (item.videos.length > 0) {
            zproducts.splice(i, 1);
            zproducts.unshift(item);
          }
        });
        zproducts.forEach(function (item, i) {
          if (item.vr_url) {
            zproducts.splice(i, 1);
            zproducts.unshift(item);
          }
        });
        var desc = response.company_description;
        var boldtext = '';
        if (desc) {
          if (desc.includes('Please contact us via WECHAT')) {
            boldtext = 'Please contact us via WECHAT' + desc.split('Please contact us via WECHAT')[1];
            desc = desc.split('Please contact us via WECHAT')[0]
          }
        }

        this.setState({
          isPaneOpenBottom: true,
          companyVR: response.is_vr,
          companyName: response.company_name,
          companyLogo: response.company_logo,
          companyDesc: desc,
          wechatBold: boldtext,
          companyHall: response.hall,
          companyBooth: response.booth_number,
          companyProducts: zproducts,
          //companyVideos: response.videos,
          companySite: response.website,
          productCategories: response.category,
          companyWhatsapp: response.whatsapp,
          companyEmail: response.email,
          companyAwards: response.awards,
          companyShort: response.short_name,
        });
      })
      .catch(error => {});
  }

  componentDidMount() {
    Modals.setAppElement(this.el);
  }

  handleClose = (e) => {
    e.preventDefault()
    this.setState({ isPaneOpenBottom: false })
  }

  render() {
    return (
      <div ref={ref => this.el = ref} >
        <button onClick={this.handleClick} className={this.props.css} id={this.props.shortname}>
          View Catalog
        </button>

        <SlidingPane
          isOpen={this.state.isPaneOpenBottom}
          title={this.state.companyBooth}
          from='bottom'
          width='100%'
          onRequestClose={() => this.setState({ isPaneOpenBottom: false })}>
          <div className="d-flex p-4 slide-top-div text-left">
            <button className="close-btn" onClick={this.handleClose}><CloseIcon /></button>
            <div className="pr-4">
              {this.state.companyLogo && <img className="slide-logo border-0" src={this.state.companyLogo} alt="company logo" height="80px" />}
              {!this.state.companyLogo && <img className="slide-logo border-0" src={Placeholder} alt="company logo" height="80px" />}
            </div>
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <div className="slide-title text-weight-bold">{this.state.companyName}</div>
                <div className="slide-company-desc">HALL {this.state.companyBooth}</div>
              </div>
              {this.state.companyVR === 1 && <Eggthemighty />}
            </div>
          </div>

          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="tab-title">
            <Tab eventKey="home" title="Company Profile">
              <div className="tab-content-subtitle">Company Description</div>
              <div className="mb-2">{this.state.companyDesc} <b style={{ letterSpacing: '0.01em', fontSize: 14 }}>{this.state.wechatBold}</b></div>
              {this.state.companySite && <div className="visit-site"><a href={this.state.companySite} className="visit-href" target="_blank" rel="noopener noreferrer">Visit Website &gt;</a></div>}
              <div className="tab-content-subtitle border-top">Product Category</div>
              <Productcategory cateprops={this.state.productCategories} />
              {this.state.companyAwards.length !== 0 && <div className="tab-content-subtitle border-top"> EFE Awards</div>}
              {this.state.companyAwards.length === 0 && <div className="tab-content-subtitle border-top mb-5">EFE Awards</div>}
              {this.state.companyAwards.length !== 0 && <Awardslist awards={this.state.companyAwards} cname={this.state.companyName} hall={this.props.hall} />}
            </Tab>
            <Tab eventKey="profile" title={this.state.companyVR ? <><span>Products /</span><img src={wow360} alt="WOW 360" height="30" className="ml-2" /></> : "Products"}>
              <Productlist products={this.state.companyProducts} cname={this.state.companyName} hall={this.props.hall} />
            </Tab>
          </Tabs>
          {/* <a href={this.state.companySite} target="_blank" rel="noopener noreferrer">Go to Exhibitor Site</a> */}
          <div className="enq-box border-top row">
            {this.state.companyEmail && <a href={"mailto: " + this.state.companyEmail} id={this.state.companyShort} target="_blank" rel="noopener noreferrer" className="col-6 border-right" style={{ color: '#EC2034' }}>Email Enquiry</a>}
            {this.state.companyWhatsapp && this.state.companyWhatsapp[0] === '0' && <a href={"https://wa.me/6" + this.state.companyWhatsapp} id={this.state.companyShort} target="_blank" rel="noopener noreferrer" className="col-6" style={{ color: '#9ACA55' }}>Whatsapp Us</a>}
            {this.state.companyWhatsapp && this.state.companyWhatsapp[0] !== '0' && <a href={"https://wa.me/" + this.state.companyWhatsapp} id={this.state.companyShort} target="_blank" rel="noopener noreferrer" className="col-6" style={{ color: '#9ACA55' }}>Whatsapp Us</a>}
          </div>

        </SlidingPane>
      </div>
    )
  };
}

export default Slidingpane;