import React from 'react';

function Eggthemighty(props) {

    return (
        <div style={{ paddingBottom: 3 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="currentColor" viewBox="0 0 3012.000000 1978.000000">
                <g transform="translate(0.000000,1978.000000) scale(0.100000,-0.100000)"
                    fill="currentColor" stroke="none">
                    <path d="M6232 19070 c-154 -21 -276 -83 -393 -200 -93 -93 -143 -170 -180
-285 -22 -67 -23 -84 -27 -467 l-3 -398 -2082 -2 -2082 -3 -78 -22 c-252 -72
-441 -265 -501 -514 -15 -59 -16 -798 -16 -8321 0 -8212 0 -8255 20 -8334 33
-133 82 -216 185 -319 102 -102 186 -151 321 -185 77 -20 172 -20 14108 -20
12244 0 14040 2 14100 15 91 19 221 83 296 146 78 65 151 164 190 256 l30 72
-2 8379 -3 8379 -42 84 c-88 179 -237 299 -448 363 l-70 21 -2082 3 -2081 2
-5 388 c-4 427 -6 439 -72 577 -87 182 -264 324 -460 371 -92 22 -240 22 -330
-1 -247 -62 -443 -256 -505 -501 -18 -70 -20 -112 -20 -456 l0 -378 -2342 -1
c-1289 0 -2351 -2 -2360 -4 -10 -2 -20 0 -22 4 -3 4 -7 176 -9 382 -3 342 -5
381 -25 450 -70 256 -255 440 -507 504 -89 23 -237 23 -328 1 -197 -47 -375
-190 -462 -371 -66 -138 -68 -150 -72 -574 -2 -211 -6 -388 -8 -392 -3 -4 -13
-6 -23 -4 -9 2 -1071 4 -2359 4 l-2343 1 0 373 c0 340 -2 381 -20 453 -38 152
-122 282 -246 381 -208 167 -514 194 -756 66 -130 -68 -263 -216 -316 -353
-47 -117 -52 -173 -52 -557 l0 -363 -2337 -2 c-1286 -2 -2350 -1 -2365 1 l-28
3 0 360 c0 383 -7 453 -53 563 -58 139 -186 280 -314 348 -126 66 -286 95
-421 77z m3194 -2750 l2324 0 0 -664 0 -665 -407 -3 -408 -3 -85 -33 c-342
-131 -528 -489 -434 -837 32 -118 82 -203 179 -301 74 -75 105 -98 175 -133
170 -82 33 -75 1620 -79 968 -2 1441 0 1497 8 134 18 237 59 345 141 84 63
148 141 199 245 55 113 72 202 66 339 -9 189 -66 316 -206 455 -68 68 -104 94
-171 128 -141 69 -164 72 -597 72 l-383 0 0 665 0 665 2343 1 c1288 0 2350 2
2359 4 10 2 20 -1 23 -5 4 -6 7 -993 5 -1320 0 -6 -134 -10 -383 -10 -435 0
-458 -3 -612 -83 -93 -48 -210 -159 -263 -249 -164 -277 -120 -624 107 -848
118 -117 239 -178 395 -199 52 -7 528 -11 1460 -11 1199 0 1395 2 1471 15 222
39 398 167 499 365 57 110 79 200 78 320 0 262 -140 492 -374 615 -132 70
-174 75 -600 75 -245 0 -378 4 -378 10 -2 327 1 1314 5 1320 3 4 13 7 23 5 9
-2 1071 -4 2360 -4 l2342 -1 0 -665 0 -665 -383 0 c-347 0 -391 -2 -459 -19
-306 -78 -519 -351 -519 -666 0 -106 11 -167 45 -258 86 -228 301 -400 549
-437 36 -5 656 -8 1497 -7 1611 3 1471 -4 1639 78 105 52 229 169 285 270 213
385 45 844 -366 1002 l-83 32 -407 3 -408 3 0 665 0 664 1650 1 c908 0 1665 1
1683 2 l32 1 3 -6107 2 -6107 -12302 -2 -12303 -3 -80 -27 c-301 -103 -485
-359 -485 -672 1 -319 233 -605 545 -671 62 -13 1559 -15 12348 -15 l12277 0
0 -665 0 -665 -13250 0 -13250 0 0 7465 0 7465 1685 0 1685 0 0 -665 0 -665
-382 0 c-432 0 -455 -3 -598 -72 -114 -54 -246 -185 -303 -298 -102 -205 -102
-444 1 -645 83 -164 256 -299 452 -352 61 -17 157 -18 1525 -18 1638 0 1506
-6 1671 75 132 65 245 177 310 309 184 374 1 825 -393 967 l-78 28 -407 4
-408 3 0 664 0 664 28 4 c15 2 33 2 40 0 8 -1 1060 -3 2338 -3z"/>
                    <path d="M12307 11643 c-54 -143 -568 -1489 -1140 -2988 -151 -396 -384 -1006
-517 -1355 -133 -349 -287 -751 -341 -894 -54 -142 -99 -263 -99 -267 0 -5
251 -9 559 -9 435 0 561 3 568 13 9 10 239 632 451 1215 l66 182 987 0 988 0
21 -57 c12 -32 66 -177 120 -323 202 -544 371 -993 381 -1011 9 -18 32 -19
564 -19 345 0 556 4 560 10 3 5 -61 186 -144 402 -82 216 -235 618 -339 893
-105 275 -481 1265 -837 2200 -356 935 -685 1802 -733 1928 l-86 227 -486 0
-487 0 -56 -147z m682 -1506 c137 -391 416 -1187 476 -1357 113 -319 155 -442
155 -450 0 -6 -286 -10 -789 -10 l-789 0 33 93 c18 50 97 265 174 477 78 212
244 665 369 1007 125 343 229 623 232 623 3 0 65 -172 139 -383z"/>
                    <path d="M16107 11784 c-4 -4 -7 -1275 -7 -2824 0 -2564 1 -2818 16 -2824 26
-10 2004 -6 2214 4 394 20 702 84 1012 211 496 203 907 545 1177 978 338 541
486 1281 405 2026 -84 768 -391 1388 -899 1814 -342 287 -782 489 -1235 566
-278 48 -271 48 -1505 52 -644 2 -1174 1 -1178 -3z m2278 -974 c276 -31 518
-117 736 -261 124 -82 304 -262 388 -389 240 -361 352 -830 321 -1351 -20
-323 -86 -582 -216 -844 -225 -454 -604 -739 -1112 -837 -99 -19 -153 -21
-699 -25 l-593 -5 0 1861 0 1861 543 0 c298 0 583 -5 632 -10z"/>
                </g>
            </svg>
        </div>
    )
};

export default Eggthemighty;